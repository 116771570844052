<template>
  <div>
    <forms-with-steps ref="forms" :steps="steps" />

    <div class="flex justify-end mb-106 pr-28">
      <app-button class="text-blue-400" @click="goHome()">{{ $t('SHARED.CANCEL') }}</app-button>
      <app-button
        :disabled="!isFormValid"
        :class="isFormValid ? 'bg-blue-500' : 'bg-grey-400'"
        @click="$refs.forms.save()"
        >{{ $t('SHARED.SEND_CHANGES') }}</app-button
      >
    </div>
  </div>
</template>

<script>
import FormsWithSteps from '@/components/forms-with-steps/FormsWithSteps'
import HomeUrl from '@/mixins/HomeUrl'
import SpecialAgreementFormSenior from './SpecialAgreementFormSenior'

export default {
  name: 'SpecialAgreementSenior',

  components: {
    FormsWithSteps,
    AppButton: () => import('@/components/AppButton'),
  },

  mixins: [HomeUrl],

  data: () => ({
    forms: undefined,
    steps: [
      {
        title: 'SPECIAL_AGREEMENT.TITLE',
        component: SpecialAgreementFormSenior,
      },
    ],
  }),

  computed: {
    isFormValid() {
      return this.forms && this.forms.isValid
    },
  },

  mounted() {
    this.forms = this.$refs.forms
  },
}
</script>
