<template>
  <div class="special-agreement-form flex flex-col w-full">
    <h1 class="ml-68 pb-0 flex-auto text-grey-900 text-24 font-semibold">
      {{ $t('SPECIAL_AGREEMENT.TITLE') }}
    </h1>

    <form class="mt-33 flex-auto" @submit.prevent>
      <div class="form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('SPECIAL_AGREEMENT_SENIOR.FORM.AMOUNT.LABEL') }}
          </label>
        </div>

        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="agreement.amount"
            type="money"
            :maxlength="11"
            :disabled="disabled"
            :error="$v.agreement.amount.$invalid && $v.agreement.amount.$dirty"
            :placeholder="$t('SPECIAL_AGREEMENT_SENIOR.FORM.AMOUNT.PLACEHOLDER')"
            @input="$v.agreement.amount.$touch()"
          />

          <div v-if="displayUpdateDate">
            {{ $t('SPECIAL_AGREEMENT_SENIOR.FORM.AMOUNT.DATE') }}
            {{ agreement.updateDate | moment('DD/MM/YYYY') }}
          </div>

          <div class="error-message text-red-500 text-15 mt-12">
            <div v-if="$v.agreement.amount.$invalid && $v.agreement.amount.$dirty">
              <p v-if="!$v.agreement.amount.minValue">
                {{ $t('SPECIAL_AGREEMENT_SENIOR.FORM.AMOUNT.ERROR_MIN') }}
              </p>

              <p v-if="!$v.agreement.amount.maxValue">
                {{ $t('SPECIAL_AGREEMENT_SENIOR.FORM.AMOUNT.ERROR_MAX') }}
              </p>
            </div>

            <p v-if="betweenClosingDates">
              {{ $t('SPECIAL_AGREEMENT_SENIOR.FORM.CLOSING_ERROR') }}
            </p>

            <p v-if="salaryProcessInCourse">
              {{ $t('SPECIAL_AGREEMENT_SENIOR.FORM.PROCESSING_ERROR') }}
            </p>
          </div>

          <p v-if="!disabled" class="mt-22">
            {{ $t('SPECIAL_AGREEMENT_SENIOR.FORM.AMOUNT.COMMENT1') }}<br />
            {{ $t('SPECIAL_AGREEMENT.FORM.AMOUNT.COMMENT2') }}
            <money v-model="agreement.maxAmount" v-bind="moneyFormat" class="bg-white" disabled />
          </p>
        </div>
      </div>
    </form>

    <p v-if="!disabled && agreement.updateDate" class="text-center">
      {{ $t('SPECIAL_AGREEMENT_SENIOR.FORM.AMOUNT.COMMENT3') }}
    </p>

    <div v-if="!disabled && !displayUpdateDate" class="mt-24 text-center">
      <span class="font-bold">{{ $t('SPECIAL_AGREEMENT_SENIOR.FORM.CLOSING_ADVISE.LINE1') }}</span
      ><br />
      {{ $t('SPECIAL_AGREEMENT_SENIOR.FORM.CLOSING_ADVISE.LINE2-1') }}
      <span class="font-bbva font-bold text-16 text-red-900">{{
        $t('SPECIAL_AGREEMENT_SENIOR.FORM.CLOSING_ADVISE.LINE2-2')
      }}</span>
      {{ $t('SPECIAL_AGREEMENT_SENIOR.FORM.CLOSING_ADVISE.LINE2-3') }}
      {{ agreement.updateBeforeDate | moment('DD/MM/YYYY') }}<br />
      {{ $t('SPECIAL_AGREEMENT_SENIOR.FORM.CLOSING_ADVISE.LINE3') }}
      <money v-model="agreement.estimated" v-bind="moneyFormat" class="bg-white" disabled /><br />
      {{ $t('SPECIAL_AGREEMENT_SENIOR.FORM.CLOSING_ADVISE.LINE4') }}<br />
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minValue, maxValue } from 'vuelidate/lib/validators'
import { mapActions, mapMutations } from 'vuex'
import { money as moneyFormat } from '@/shared/constants/formats'
import { Money } from 'v-money'
import AppInput from '@/components/form/AppInput'
import ConfirmSaveModal from '../ConfirmSaveModal'
import employeeService from '@/shared/services/employeeService'

export default {
  name: 'SpecialAgreementFormSenior',

  components: {
    AppInput,
    Money,
  },

  mixins: [validationMixin],

  data: () => ({
    moneyFormat,
    response: undefined,
    agreement: {
      amount: undefined,
    },
  }),

  computed: {
    displayUpdateDate() {
      return !!(this.agreement.reasonCode !== '01' && this.agreement.updateDate)
    },

    betweenClosingDates() {
      const now = new Date()

      const { closingStartDate: startDate, closingEndDate: endDate } = this.agreement

      return !!(now >= startDate && now <= endDate)
    },

    salaryProcessInCourse() {
      return !!(this.response && this.response.indexOf('Proceso de nómina en curso') !== -1)
    },

    disabled() {
      return this.betweenClosingDates || this.salaryProcessInCourse
    },

    isValid() {
      return !this.disabled && !this.$v.$invalid
    },
  },

  validations() {
    return {
      agreement: {
        amount: {
          required,
          minValue: minValue(this.agreement.minAmount),
          maxValue: maxValue(this.agreement.maxAmount),
        },
      },
    }
  },

  created() {
    this.loadSpecialAgreementSeniorData()
  },

  methods: {
    ...mapMutations('senior', ['setRequestInProgressStatus']),

    ...mapActions('messages', {
      addMessage: 'add',
      emptyMessages: 'empty',
    }),

    loadSpecialAgreementSeniorData(reset = false) {
      employeeService.getSpecialAgreementSenior().then((response) => {
        if (reset) {
          this.$v.$reset()
        }
        this.agreement = response
      })
    },

    openConfirmSave() {
      const props = {
        amount: this.agreement.amount,
      }

      const options = {
        maskClass: 'bg-mask',
      }

      this.$modal.open(ConfirmSaveModal, props, options).then((response) => {
        response.confirm && this.save(true)
      })
    },

    reset() {
      this.loadSpecialAgreementSeniorData(true)
    },

    save(confirmed = false) {
      if (!this.isValid) {
        return
      }

      this.emptyMessages()

      if (!confirmed) {
        this.openConfirmSave()
        return
      }

      this.setRequestInProgressStatus(true)

      employeeService
        .postSpecialAgreementSenior(this.agreement.amount)
        .then((response) => this.manageSaveResponse(response))
        .finally(() => {
          this.setRequestInProgressStatus(false)
        })
    },

    manageSaveResponse(response) {
      if (response.estado !== 'OK') {
        this.response = response.literalMensaje
        return
      }

      this.$v.$reset()

      this.agreement.updateDate = new Date()
      this.agreement.reasonCode = '02'

      this.addMessage({
        type: 'success',
        text: this.$t('SPECIAL_AGREEMENT.FORM.SUCCESS'),
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.my-profile-form {
  .buttons {
    max-height: 49px;
  }
}
</style>
