<template>
  <app-section class="animated fadeIn">
    <div class="title flex-initial my-32 pb-32 text-46 font-semibold text-grey-900">
      {{ $t('SPECIAL_AGREEMENT.TITLE') }}
    </div>

    <div v-if="isDc" class="description text-20 text-grey-500 pb-12">
      {{ $t('SPECIAL_AGREEMENT_DC.DESCRIPTION') }}
    </div>

    <div class="flex justify-end">
      <a href="javascript: void(0)" @click="openInformationModal()">
        <information :text="$t('SPECIAL_AGREEMENT.MORE_INFO')" class="mt-38 mb-95" />
      </a>
    </div>

    <special-agreement-dc v-if="isDc"></special-agreement-dc>
    <special-agreement-senior v-if="isSenior"></special-agreement-senior>
  </app-section>
</template>

<script>
import AppSection from '@/components/AppSection'
import Information from '@/components/Information'
import InformationModal from '@/components/modals/InformationModal'
import HomeUrl from '@/mixins/HomeUrl'
import SpecialAgreementDc from './components/dc/SpecialAgreementDc.vue'
import SpecialAgreementSenior from './components/senior/SpecialAgreementSenior.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SpecialAgreement',

  components: {
    AppSection,
    Information,
    SpecialAgreementDc,
    SpecialAgreementSenior,
  },

  mixins: [HomeUrl],

  computed: {
    ...mapGetters('auth', ['isDc', 'isSenior', 'isSpecialAgreementAllowed']),
  },

  watch: {
    homeId() {
      // TODO: move to a guard
      if (this.homeId !== undefined) {
        this.checkRedirectToHome()
      }
    },
  },

  methods: {
    checkRedirectToHome() {
      if (this.homeId && !this.isSpecialAgreementAllowed) {
        this.goHome()
      }
    },

    openInformationModal() {
      let translationKeys

      if (this.isDc) {
        translationKeys = {
          title: 'SPECIAL_AGREEMENT.MODALS.INFORMATION.TITLE',
          paragraphs: [
            'SPECIAL_AGREEMENT_DC.MODALS.INFORMATION.P1',
            'SPECIAL_AGREEMENT_DC.MODALS.INFORMATION.P2',
            'SPECIAL_AGREEMENT_DC.MODALS.INFORMATION.P3',
            'SPECIAL_AGREEMENT_DC.MODALS.INFORMATION.P4',
            'SPECIAL_AGREEMENT_DC.MODALS.INFORMATION.P5',
            'SPECIAL_AGREEMENT_DC.MODALS.INFORMATION.P6',
            'SPECIAL_AGREEMENT_DC.MODALS.INFORMATION.P7',
            'SPECIAL_AGREEMENT_DC.MODALS.INFORMATION.P8',
          ],
        }
      } else {
        translationKeys = {
          title: 'SPECIAL_AGREEMENT.MODALS.INFORMATION.TITLE',
          paragraphs: [
            'SPECIAL_AGREEMENT_SENIOR.MODALS.INFORMATION.P1',
            'SPECIAL_AGREEMENT_SENIOR.MODALS.INFORMATION.P2',
            'SPECIAL_AGREEMENT_SENIOR.MODALS.INFORMATION.P3',
            'SPECIAL_AGREEMENT_SENIOR.MODALS.INFORMATION.P4',
          ],
        }
      }

      const props = {
        translationKeys,
        email: 'sae.es@sae.bbva.com',
      }

      const options = {
        closeOnClickMask: true,
        maskClass: 'bg-mask',
      }

      this.$modal.open(InformationModal, props, options)
    },
  },
}
</script>
