<template>
  <div>
    <template v-if="isFormVisible">
      <div class="mt-24">
        <label v-if="requests.length > 0" class="text-16 text-gray-900">
          {{ $t('SPECIAL_AGREEMENT_DC.REQUEST_DATA') }}
        </label>
        <div v-for="(request, index) in requests" :key="index" class="bg-grey-100 mt-16 p-16">
          <div class="flex w-3/5 flex-row flex-wrap p-1 ml-10">
            <div class="flex-1">
              <label class="font-bbva">
                {{ $t('SPECIAL_AGREEMENT_DC.FORM.FROM') }}
              </label>
            </div>
            <div class="flex-1">
              <label class="font-bbva">
                {{ formatDate(request.fromDate) }}
              </label>
            </div>
          </div>
          <div class="flex w-3/5 flex-row flex-wrap p-1 ml-10">
            <div class="flex-1">
              <label class="font-bbva">
                {{ $t('SPECIAL_AGREEMENT_DC.FORM.UNTIL') }}
              </label>
            </div>
            <div class="flex-1">
              <label class="font-bbva">
                {{ formatDate(request.untilDate) }}
              </label>
            </div>
          </div>
          <div class="flex w-3/5 flex-row flex-wrap p-1 ml-10">
            <div class="flex-1">
              <label class="font-bbva">
                {{ $t('SPECIAL_AGREEMENT_DC.FORM.AMOUNT.LABEL') }}
              </label>
            </div>
            <div class="flex-1 mb-11">
              <money v-model="request.amount" v-bind="moneyFormat" disabled />
            </div>
          </div>
        </div>
      </div>

      <forms-with-steps
        v-if="isFormEnabled"
        ref="forms"
        :steps="steps"
        @reloadSpecialAgreementDcData="loadSpecialAgreementDcData"
      />

      <div v-if="isFormEnabled" class="flex justify-end mb-106 pr-28 mt-80">
        <app-button class="text-blue-400" @click="goHome()">{{ $t('SHARED.CANCEL') }}</app-button>
        <app-button
          :disabled="!isFormValid"
          :class="isFormValid ? 'bg-blue-500' : 'bg-grey-400'"
          @click="openConfirmSave()"
          >{{ $t('SHARED.SEND_CHANGES') }}</app-button
        >
      </div>
    </template>

    <template v-else>
      <app-process-info
        class="mt-24"
        :translation-keys="processInfoTranslationKeys"
        :document="document"
        :fields="fields"
      ></app-process-info>
    </template>
    <div v-if="!isFormEnabled || !isFormVisible" class="flex justify-end mb-106 pr-28 mt-80">
      <app-button class="bg-blue-500" @click="goHome()">
        {{ $t('SHARED.ACCEPT') }}
      </app-button>
    </div>
  </div>
</template>

<script>
import FormsWithSteps from '@/components/forms-with-steps/FormsWithSteps'
import HomeUrl from '@/mixins/HomeUrl'
import SpecialAgreementFormDc from './SpecialAgreementFormDc'
import ConfirmSaveModal from '../ConfirmSaveModal'
import employeeService from '@/shared/services/employeeService'
import { mapMutations } from 'vuex'
import { DateFormatter } from '@/shared/utils/dateFormatter'
import { Money } from 'v-money'
import { money as moneyFormat } from '@/shared/constants/formats'

export default {
  name: 'SpecialAgreementDc',

  components: {
    FormsWithSteps,
    Money,
    AppButton: () => import('@/components/AppButton'),
    AppProcessInfo: () => import('@/components/AppProcessInfo'),
  },

  mixins: [HomeUrl],

  data: () => ({
    forms: undefined,
    steps: [
      {
        title: 'SPECIAL_AGREEMENT.TITLE',
        component: SpecialAgreementFormDc,
      },
    ],
    document: undefined,
    fields: undefined,
    requests: [],
    moneyFormat,
  }),

  computed: {
    isFormValid() {
      return this.forms && this.forms.isValid
    },

    isFormVisible() {
      return !(this.fields && this.fields.length > 0 && this.document)
    },

    isFormEnabled() {
      return this.requests.length < 3 || !this.requests
    },

    processInfoTranslationKeys() {
      return {
        infoMsg: 'SPECIAL_AGREEMENT_DC.PROCESS_INFO',
      }
    },
  },

  mounted() {
    this.forms = this.$refs.forms
    this.loadSpecialAgreementDcData()
  },

  methods: {
    ...mapMutations('portal', ['setRequestInProgressStatus']),
    formatDate(date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return date ? DateFormatter.formatDateInSpanish(new Date(date), options) : ''
    },
    loadSpecialAgreementDcData() {
      this.fields = []
      this.document = undefined
      this.requests = []
      this.setRequestInProgressStatus(true)
      employeeService
        .getSpecialAgreementDc()
        .then((data) => {
          if (data) {
            this.handleData(data)
          }
        })
        .finally(() => {
          this.setRequestInProgressStatus(false)
        })
    },

    handleData(data) {
      this.forms.$refs.form.agreement.maxAmount = data.maxAmount / 12
      this.forms.$refs.form.agreement.minAmount = data.minAmount / 12

      if (data.requests && data.requests.length > 0) {
        const pendingRequest = data.requests.find((request) => request.isPending)
        if (pendingRequest) {
          this.buildPendingTramit(pendingRequest)
        } else {
          this.requests = data.requests
          this.requests.sort((a, b) => a.fromDate.replace(/-/g, '') - b.fromDate.replace(/-/g, ''))
        }
      }
    },

    buildPendingTramit(pendingRequest) {
      this.document = {
        name: pendingRequest.file.name,
        data: pendingRequest.file.data,
      }
      this.fields = [
        {
          label: 'SPECIAL_AGREEMENT_DC.FORM.FROM',
          description: this.formatDate(pendingRequest.fromDate),
        },
        {
          label: 'SPECIAL_AGREEMENT_DC.FORM.UNTIL',
          description: this.formatDate(pendingRequest.untilDate),
        },
        {
          label: 'SPECIAL_AGREEMENT_DC.FORM.AMOUNT.LABEL',
          description: pendingRequest.amount,
          type: 'money',
        },
      ]
    },

    openConfirmSave() {
      const props = {
        amount: this.forms.$refs.form.agreement.amount,
      }

      const options = {
        closeOnClickMask: false,
        maskClass: 'bg-mask',
      }

      this.$modal.open(ConfirmSaveModal, props, options).then((response) => {
        response.confirm && this.forms.save()
      })
    },
  },
}
</script>

<style></style>
