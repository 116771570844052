<template>
  <div
    class="special-agreement-information-modal flex flex-col items-center p-64 pt-48 text-grey-900 text-center"
  >
    <img src="/imgs/send-documents.svg" alt="send" />

    <h1 class="mt-40 text-40 leading-125 tracking-048">
      <span v-if="amount">{{ $t('SPECIAL_AGREEMENT.MODALS.CONFIRMATION.TITLE') }}</span>
      <span v-if="!amount">{{ $t('SPECIAL_AGREEMENT.MODALS.CONFIRMATION.TITLE_0') }}</span>
    </h1>

    <p class="mt-48 text-22 tracking-026">
      {{ $t(confirmationTextKey) }}<br />
      <money
        v-model="amount"
        v-bind="moneyFormat"
        class="mt-12 text-center bg-white font-bbva font-medium"
        disabled
      />
    </p>

    <app-button class="mt-48 bg-blue-500 font-bbva px-32 py-16" @click="confirm()">{{
      $t('SHARED.SEND_CHANGES')
    }}</app-button>

    <a
      href="javascript: void(0)"
      class="mt-16 text-blue-400 font-bbva font-medium text-15 leading-160"
      @click="$modal.close()"
      >{{ $t('SHARED.CANCEL') }}</a
    >
  </div>
</template>

<script>
import AppButton from '@/components/AppButton'
import { money as moneyFormat } from '@/shared/constants/formats'
import { Money } from 'v-money'
import { mapGetters } from 'vuex'

export default {
  name: 'ConfirmSaveModal',

  components: {
    AppButton,
    Money,
  },

  props: {
    amount: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    moneyFormat,
  }),

  computed: {
    ...mapGetters('auth', ['isDc']),

    confirmationTextKey() {
      return this.isDc
        ? 'SPECIAL_AGREEMENT_DC.MODALS.CONFIRMATION.P1'
        : 'SPECIAL_AGREEMENT_SENIOR.MODALS.CONFIRMATION.P1'
    },
  },

  methods: {
    confirm() {
      this.$modal.close(true, {
        confirm: true,
      })
    },
  },
}
</script>
