<template>
  <div class="special-agreement-form flex flex-col w-full">
    <h1 class="ml-68 pb-0 flex-auto text-grey-900 text-24 font-semibold">
      {{ $t('SPECIAL_AGREEMENT.TITLE') }}
    </h1>

    <form class="mt-33 flex-auto" @submit.prevent>
      <div class="form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('SPECIAL_AGREEMENT_DC.FORM.FROM') }} *
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-datepicker
            :date="agreement.dateFrom"
            :is-blank="true"
            :to="fromDatePickerTo"
            :is-input-disabled="true"
            @date-selected="agreementDateSelectedFrom"
          />
        </div>
      </div>

      <div class="form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('SPECIAL_AGREEMENT_DC.FORM.UNTIL') }}
          </label>
        </div>
        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-datepicker
            :date="agreement.dateUntil"
            :is-blank="true"
            :to="agreement.dateFrom"
            :from="untilDatePickerFrom"
            :is-input-disabled="true"
            :is-clear-enabled="true"
            @date-selected="agreementDateSelectedUntil"
          />
        </div>
      </div>

      <div class="form-row-wrapper md:flex mb-40">
        <div class="flex-auto w-1/3 ml-68 pt-10">
          <label class="text-16 text-gray-900">
            {{ $t('SPECIAL_AGREEMENT_DC.FORM.AMOUNT.LABEL') }} *
          </label>
        </div>

        <div class="w-1/3 max-w-md flex-auto mr-28">
          <app-input
            v-model="agreement.amount"
            type="money"
            :maxlength="11"
            :error="$v.agreement.amount.$invalid && $v.agreement.amount.$dirty"
            :placeholder="$t('SPECIAL_AGREEMENT_DC.FORM.AMOUNT.PLACEHOLDER')"
            @input="$v.agreement.amount.$touch()"
          />
          <p class="mt-22">
            {{ $t('SPECIAL_AGREEMENT.FORM.AMOUNT.COMMENT2') }}
            <money v-model="agreement.maxAmount" v-bind="moneyFormat" class="bg-white" disabled />
          </p>
        </div>
      </div>

      <div class="attach-file-field form-row-wrapper md:flex mb-40">
        <div class="flex flex-auto w-1/3 ml-68">
          <label class="text-16 text-gray-900"> {{ $t('FILE_UPLOADER.LABEL') }} *</label>
          <div>
            <app-tooltip
              class="ml-45 cursor-pointer"
              :translation-keys="tooltipProps.translationKeys"
              :placement="tooltipProps.placement"
              :offset="tooltipProps.offset"
              :delay="tooltipProps.delay"
            ></app-tooltip>
          </div>
        </div>

        <div class="w-1/3 max-w-md mr-28 mt-3">
          <app-file-uploader
            ref="fileUploader"
            :text="$t('FILE_UPLOADER.PLACEHOLDER')"
            @fileSelected="setFile"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minValue, maxValue } from 'vuelidate/lib/validators'
import { mapMutations, mapActions } from 'vuex'
import AppDatepicker from '@/components/form/AppDatepicker'
import employeeService from '@/shared/services/employeeService'
import { Money } from 'v-money'
import { money as moneyFormat } from '@/shared/constants/formats'
import Alert from '@/components/modals/Alert'

export default {
  name: 'SpecialAgreementFormDc',

  components: {
    Money,
    AppDatepicker,
    AppFileUploader: () => import('@/components/form/AppFileUploader'),
    AppInput: () => import('@/components/form/AppInput'),
    AppTooltip: () => import('@/components/AppTooltip'),
  },

  mixins: [validationMixin],

  data: () => ({
    moneyFormat,
    agreement: {
      amount: undefined,
      dateUntil: undefined,
      dateFrom: undefined,
      minAmount: undefined,
      maxAmount: undefined,
    },
    files: [],
  }),

  computed: {
    tooltipProps() {
      return {
        translationKeys: { tooltipMsg: 'TOOLTIP.EXTENSIONS' },
        placement: 'bottom-center',
        offset: 20,
        delay: {
          show: 50,
          hide: 800,
        },
      }
    },

    isValid() {
      return !this.$v.$invalid
    },

    fromDatePickerTo() {
      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = currentDate.getMonth() === 0 ? 1 : currentDate.getMonth()
      const day = 1
      const date = new Date(year + '/' + month + '/' + day)
      date.setHours(0, 0, 0, 0)
      return date
    },

    untilDatePickerFrom() {
      const date = new Date()
      date.setMonth(11, 31)
      return date
    },
  },

  methods: {
    ...mapActions('messages', {
      addMessage: 'add',
      emptyMessages: 'empty',
    }),
    ...mapMutations('portal', ['setRequestInProgressStatus']),

    setFile(file) {
      this.files = file ? [file] : []
    },

    agreementDateSelectedUntil(date) {
      this.agreement.dateUntil = date
    },

    agreementDateSelectedFrom(date) {
      this.agreement.dateFrom = date
      this.$v.agreement.dateFrom.$touch()
    },

    save() {
      if (!this.isValid) {
        return
      }

      this.emptyMessages()
      this.setRequestInProgressStatus(true)

      const requestData = {
        startDate: this.agreement.dateFrom.toISOString().substr(0, 10),
        endDate: this.agreement.dateUntil && this.agreement.dateUntil.toISOString().substr(0, 10),
        amount: this.agreement.amount,
        files: [...this.files],
      }

      employeeService
        .postSpecialAgreementDc(requestData)
        .then((response) => {
          this.manageSaveResponse(response)
        })
        .finally(() => {
          this.setRequestInProgressStatus(false)
        })
    },

    reset() {
      this.agreement.dateFrom = ''
      this.agreement.dateUntil = ''
      this.agreement.amount = 0
      this.files = []
      this.$refs.fileUploader.deleteFile()
      this.$v.$reset()
    },

    manageSaveResponse(response) {
      if (response.estado !== 'OK') {
        this.openUpdateErrorModal(response.literalMensaje)
      } else {
        this.reset()
        this.$parent.$emit('reloadSpecialAgreementDcData')

        this.addMessage({
          type: 'success',
          text: this.$t('SPECIAL_AGREEMENT.FORM.SUCCESS'),
        })
      }
    },

    openUpdateErrorModal(text = 'MODALS.ERROR_UPDATE_DATA.TEXT') {
      const props = {
        translationKeys: {
          title: 'MODALS.ERROR_UPDATE_DATA.TITLE',
          text,
        },
      }

      this.$modal.open(Alert, props, { maskClass: 'bg-mask' })
    },
  },

  validations() {
    return {
      agreement: {
        amount: {
          required,
          minValue: minValue(this.agreement.minAmount),
          maxValue: maxValue(this.agreement.maxAmount),
        },
        dateFrom: { required },
      },
      files: {
        required,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-file-uploader {
  // stylelint-disable-next-line selector-max-id
  #fileInput {
    visibility: hidden;
  }

  .information-component {
    // stylelint-disable-next-line selector-max-compound-selectors
    ::v-deep .icon {
      transform: rotate(180deg);
    }
  }
}
</style>
