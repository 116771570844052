<template>
  <div class="information-modal flex flex-col items-center p-64 text-grey-900 text-center">
    <img src="/imgs/info-big.svg" alt="info" />

    <h1 class="mt-42 mb-56 text-40 leading-125 tracking-048">
      {{ $t(translationKeys.title) }}
    </h1>

    <p
      v-for="(paragraph, index) in translationKeys.paragraphs"
      :key="index"
      class="mb-42 text-22 tracking-026"
      v-html="$t(paragraph, { email, phone1, phone2 })"
    />

    <app-button class="bg-blue-500 font-bbva" @click="closeModal()">{{
      $t('SHARED.CLOSE')
    }}</app-button>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton'

export default {
  name: 'InformationModal',

  components: {
    AppButton,
  },

  props: {
    translationKeys: { required: true, type: Object },
    email: { type: String, required: false },
    phone1: { type: String, required: false },
    phone2: { type: String, required: false },
  },

  methods: {
    closeModal() {
      this.$modal.close()
    },
  },
}
</script>
